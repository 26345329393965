<div class="p-4  connect">

  <!-- ----- Step 0 ------ -->

  <div class="d-flex justify-content-between align-items-center" *ngIf="step === 0">
    <div></div>
    <h4 class="text-center fs-3 m-0 text-white" translate>misc.connect_wallet</h4>
    <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
      class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
  </div>

  <div class="mt-4 content" *ngIf="step === 0">

    <button type="button"
      class="btn btn-sm border-0 rounded-1 bg-tertiary py-2 px-3 btn-light w-100 d-flex justify-content-between align-items-center mb-3"
      (click)="connectWallet('wallet')">
      <span class="fs-5 d-inline-block text-black" translate>popup.wallet_connect</span>
      <svg-icon src="/assets/images/svg-icons/wallet.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
    </button>

    <!-- <button type="button"
      class="btn btn-sm border-0 rounded-1 bg-tertiary py-2 px-3 btn-light w-100 d-flex d-md-none justify-content-between align-items-center mb-3"
      (click)="connectWallet('bw')">
      <span class="fs-5 d-inline-block text-black" translate>misc.best_wallet</span>
      <img src="/assets/images/bw.png" height="32" width="32" alt="">
    </button> -->

    <button type="button"
      class="btn btn-sm border-0 rounded-1 bg-tertiary py-2 px-3 btn-light w-100 d-flex justify-content-between align-items-center mb-3"
      (click)="openBestWallet(bestwallet);connectWallet('bw')">
      <span class="fs-5 d-inline-block text-black" translate>misc.best_wallet</span>
      <img src="/assets/images/bw.png" height="32" width="32" alt="">
    </button>


    <button type="button"
      class="btn btn-sm border-0 rounded-1 bg-tertiary py-2 px-3 btn-light w-100 d-none d-md-flex justify-content-between align-items-center mb-3"
      (click)="connectWallet('metamask')">
      <span class="fs-5 d-inline-block text-black" translate>popup.metamask</span>
      <svg-icon src="/assets/images/svg-icons/metamask.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
    </button>


    <button type="button"
      class="btn btn-sm border-0 rounded-1 bg-tertiary py-2 px-3 btn-light w-100 d-flex justify-content-between align-items-center mb-3"
      (click)="connectWallet('coinbase')">
      <span class="fs-5 d-inline-block text-black" translate>popup.coinbase_connect</span>
      <svg-icon src="/assets/images/svg-icons/coinbase.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
    </button>

    <!-------------- i dont have wallet  ---------->

    <a href="https://best.sng.link/Dnio2/rn0u?_smtype=3" target="_blank" class="d-block d-md-none">
      <button type="button" class="btn btn-clear w-100 d-flex justify-content-center align-items-center aniBtn">
        <svg-icon src="/assets/images/svg-icons/ani-wallet.svg"
          [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
        <span class="d-inline-block text-light" translate>popup.no_wallet</span>
      </button>
    </a>

    <button type="button" class="d-none btn btn-clear w-100 d-md-flex justify-content-center align-items-center aniBtn"
      (click)="openBestWallet(bestwallet)">
      <svg-icon src="/assets/images/svg-icons/ani-wallet.svg"
        [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
      <span class="d-inline-block text-light" translate>popup.no_wallet</span>
    </button>

   <!-- <a href="https://best.sng.link/Dnio2/rn0u?_smtype=3" target="_blank">
    <button type="button" class="btn btn-clear w-100 d-flex justify-content-center align-items-center aniBtn" >
      <svg-icon src="/assets/images/svg-icons/ani-wallet.svg" [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
      <span class="d-inline-block text-light" translate>popup.no_wallet</span>
    </button>
   </a>  -->

    <!-- <button type="button" class="btn btn-clear w-100 d-flex justify-content-center align-items-center aniBtn" (click)="step = 1">
      <svg-icon src="/assets/images/svg-icons/ani-wallet.svg" [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
      <span class="d-inline-block text-light" translate>popup.no_wallet</span>
    </button> -->
  </div>


  <!-- ----- Step 1 ------ -->

  <div class="d-flex justify-content-between align-items-center" *ngIf="step === 1">
    <svg-icon src="/assets/images/svg-icons/back.svg" [svgStyle]="{ 'width.px':9, 'height.px':16 }"
      class="cursor-pointer white" (click)="step = 0"></svg-icon>
    <h4 class="text-center fs-3 m-0 text-white" translate>popup.get_wallet</h4>
    <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
      class="cursor-pointer white" (click)="modalRef.hide()"></svg-icon>
  </div>

  <div class="mt-4 content" *ngIf="step === 1">

    <div class="hoHHXf">
      <div class="sc-fLcnxK fWzKsv">
        <div class="sc-ezOQGI cSaAIp">
          <div class="sc-iveFHk jBAplQ" data-projection-id="1250">
            <div class="sc-cwSeag fsywKZ" data-projection-id="1251">
              <div class="sc-iJnaPW iUmxfL" data-projection-id="1252">
                <div class="sc-gikAfH evkwbC" data-projection-id="1253">
                  <div class="sc-lllmON bPXKDV" data-projection-id="1254">
                    <svg-icon src="/assets/images/svg-icons/animated/1.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sc-ezOQGI cSaAIp">
          <div class="sc-iveFHk jBAplQ" data-projection-id="1256">
            <div class="sc-cwSeag fsywKZ" data-projection-id="1257">
              <div class="sc-iJnaPW iUmxfL" data-projection-id="1258">
                <div class="sc-gikAfH evkwbC" data-projection-id="1259">
                  <div class="sc-lllmON bPXKDV" data-projection-id="1260">
                    <svg-icon src="/assets/images/svg-icons/animated/2.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sc-ezOQGI cSaAIp">
          <div class="sc-iveFHk jBAplQ" data-projection-id="1262">
            <div class="sc-cwSeag fsywKZ" data-projection-id="1263">
              <div class="sc-iJnaPW iUmxfL" data-projection-id="1264">
                <div class="sc-gikAfH evkwbC" data-projection-id="1265">
                  <div class="sc-lllmON bPXKDV" data-projection-id="1266">
                    <svg-icon src="/assets/images/svg-icons/animated/3.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sc-ezOQGI cSaAIp">
          <div class="sc-iveFHk jBAplQ" data-projection-id="1268">
            <div class="sc-cwSeag fsywKZ" data-projection-id="1269">
              <div class="sc-iJnaPW iUmxfL" data-projection-id="1270">
                <div class="sc-gikAfH evkwbC" data-projection-id="1271">
                  <div class="sc-lllmON bPXKDV" data-projection-id="1272">
                    <svg-icon src="/assets/images/svg-icons/animated/4.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sc-ezOQGI cSaAIp">
          <div class="sc-iveFHk jBAplQ" data-projection-id="1274">
            <div class="sc-cwSeag fsywKZ" data-projection-id="1275">
              <div class="sc-iJnaPW iUmxfL" data-projection-id="1276">
                <div class="sc-gikAfH evkwbC" data-projection-id="1277">
                  <div class="sc-lllmON bPXKDV" data-projection-id="1278">
                    <svg-icon src="/assets/images/svg-icons/animated/5.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-bBABsx jLseHP">
        <svg-icon src="/assets/images/svg-icons/animated/bg.svg"></svg-icon>
      </div>
    </div>


    <div class="text-center">
      <h5 class="text-light" translate>popup.exploring_web</h5>
      <p class="mt-3 text-light" translate>popup.exploring_des</p>
      <a type="button"
        class="btn btn-sm btn-secondary w-100 d-flex justify-content-center align-items-center mt-4 text-light"
        href="https://ethereum.org/en/wallets/find-wallet/" target="_blank">
        <span class="d-inline-block me-2" translate>popup.choose_first_wallet</span>
        <svg-icon src="/assets/images/svg-icons/ani-arrow.svg" [svgStyle]="{ 'width.px':13, 'height.px':12 }"
          class="arrow"></svg-icon>
      </a>
    </div>

  </div>

</div>


<!-- ----- Best Wallet ------ -->

<ng-template #bestwallet>
  <div class="p-4 connect text-light">

    <div class="d-flex justify-content-between align-items-center">
      <div></div>
      <h4 class="text-center  fw-semibold font-28 m-0" translate>popup.need_wallet</h4>
      <svg-icon src="/assets/images/svg-icons/close.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
        class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
    </div>

    <div class="mt-4 content d-flex flex-column align-items-center justify-content-between">

      <div class="text-center d-none d-md-block pb-2" translate>popup.install_app</div>
      <div class="text-center d-block d-md-none pb-2" translate>popup.install_app_mob</div>
      <div class="d-flex align-items-center justify-content-around">
        <div class="d-flex flex-column align-items-center mt-2">
          <!-- <div class="fw-bold fs-4" translate>popup.android</div> -->
          <div class="qr-div d-none d-md-flex flex-column align-items-center bg-white p-3">
            <img src="/assets/images/qr.svg" alt="qr" loading="lazy" class="qr-img" height="300" width="300">
          </div>
        </div>
      </div>
      <a href="https://best.sng.link/Dnio2/rn0u?_smtype=3" target="_blank"
        class="mt-2 d-md-none w-100 d-flex align-items-center justify-content-center">
        <button type="button" translate
          class="btn btn-secondary border-white py-2 px-3  w-50 d-flex justify-content-center align-items-center d-block d-md-none">
          popup.download_bw </button>
      </a>

      <div class="text-center fw-semibold my-3 fs-4" translate>popup.already_have</div>
      <div class="text-center mb-3" translate>popup.hit_btn_connect</div>

      <button type="button" class="btn btn-secondary border-white  py-2 px-3  w-50 d-flex justify-content-center align-items-center mb-3 d-none d-md-block"
        (click)="connectWallet('walletBW')">
        <span class="d-inline-block" translate>misc.connect_wallet</span>
      </button>

      <button type="button" class="btn btn-secondary border-white  py-2 px-3  w-50 d-flex justify-content-center align-items-center mb-3 d-block d-md-none"
        (click)="connectWallet('bw')">
        <span class="d-inline-block" translate>misc.connect_wallet</span>
      </button>

    </div>

  </div>
</ng-template>