import { Component, ComponentRef, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


import { environment } from 'src/environments/environment';
import { PopupService } from 'src/app/services/popup.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { EventService } from 'src/app/services/event.service';
import { SwapStatus, convertNum, getParamWithoutCookie } from 'src/app/shared/constants/app-enums';
import { ConnectComponent } from 'src/app/modals/connect/connect.component';
import { SwapComponent } from 'src/app/components/swap/swap.component';




@Component({
  selector: 'app-buy-widget',
  templateUrl: './buy-widget.component.html',
  styleUrls: ['./buy-widget.component.scss']
})

export class BuyWidgetComponent implements OnInit, OnDestroy {

  @ViewChild(SwapComponent) swapComp: any;
  private subscriptions: Subscription[] = [];

  lang: any = localStorage.getItem('currentlang');
  isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches) || screen.width <= 1024;

  modalRef?: BsModalRef;
  emailId = '';
  tokenSold = 0;
  totalTokens = 0;
  hashKey: string = '';
  windowSize: number = 0;
  usdRaised = 0;
  currentStep = 0;
  selectedLanguage: any;
  userStaked: number = 0;
  currentLanguage: any;
  connectInterval: any;
  usdtBal = 0;
  ethBal = 0;
  userClaimable: number = 0;
  userEthClaimable: number = 0;
  userBscClaimable: number = 0;
  refreshId: any;
  refreshRate: number = 1000;
  dataLoaded: boolean = false;
  oversale: boolean = false;
  salePrice: number = 0.035;
  liveCounter: any;
  isLive: boolean = true;
  claimMode: boolean = false;
  swapMode: string = 'native';
  isNative: boolean = true;
  isWert: boolean = false;

  SwapStatus = SwapStatus;
  timeInterval: any;
  showBW: boolean = false;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches) || screen.width <= 1024;
  }

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private popupService: PopupService,
    private modalService: BsModalService,
    private walletConnectService: WalletConnectService,
    private eventService: EventService,
    private renderer: Renderer2
  ) {
    this.eventService.getLang().subscribe((val) => {
      this.lang = val || 'en';
    });
  }

  ngOnInit(): void {
    setTimeout(() => this.getBalanceData(), this.refreshRate);
  }

  ngAfterViewInit(): void {
    const bwUrl = getParamWithoutCookie('bwUrl');
    if (bwUrl && bwUrl != '') this.showBW = true;
  }

  connectBestWallet() {
    window.scroll({ top: 10 });
    this.walletConnectService.connectWallet("bw");
  }

  getBalanceData() {
    const balanceData: any = this.walletConnectService.balanceData;
    this.ethBal = balanceData.nativeBal;
    this.usdtBal = balanceData.usdtBal;
    this.salePrice  = balanceData.bnbUsdt;
    this.dataLoaded = true;

    if (this.refreshId) clearTimeout(this.refreshId);
    this.refreshId = setTimeout(() => this.getBalanceData(), this.refreshRate);
  }

  connectModal(): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static'
    });
  }


  convertNum(amount: number) {
    return convertNum(amount);
  }

  openSwap(isNative: boolean = true, isWert: boolean = false): void {
    if (this.swapComp.wertMode) {
      this.popupService.messagePopup("warning", "",
        this.translate.instant("popup.are_you_sure"),
        this.translate.instant("misc.Confirm"),
        this.translate.instant("misc.Cancel"))
        .then((result: any) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.swapComp.wertMode = false;
            this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
            this.isNative = isNative;
            this.isWert = isWert;
          }
        });
    } else {
      this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
      this.isNative = isNative;
      this.isWert = isWert;
    }
    setTimeout(() => this.swapComp.max(), 1000);
  }

  changeChain(mode: string): void {
    if (mode == this.modeName) return;
    if (mode == 'SOL') {
      window.location.host = "solana." + window.location.host
      return;
    }
    let chain = environment.bsc;
    if (this.walletAddress) {
      this.walletConnectService.switchNetwork(chain.chainIdInt);
    } else {
      this.walletConnectService.chainId = chain.chainIdInt;
    }

  }
  get walletAddress(): any {
    return this.walletConnectService.walletAddress;
  }

  get tokenName() {
    return environment.tokenName;
  }

  ngOnDestroy(): void {

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  get status() {
    return this.walletConnectService.swapStatus;
  }

  get modeName() {
    return this.walletConnectService.getChainData().purchaseToken;
  }

  async copy() {
    await this.popupService.messsageAlert('success', this.translate.instant("misc.successCopy"));
  }

  // get referUrl() {
  //   return this.walletConnectService.referUrl;
  // }

  wertWidget(popup: any) {
    this.modalRef = this.modalService.show(popup, {
      class: 'modal-dialog-centered',
      backdrop: 'static'
    });
  }

  yourPurchasedInfo() {
    this.popupService.messagePopup("info", this.translate.instant("popup.your_purchased_popup_info"));
  }

  changeToChain() {
    this.popupService.messagePopup("info", this.translate.instant("popup.change_chain_info"));
  }

  navigateToSection(section: string) {
    this.hashKey = section;
    if (section) {
      window.location.hash = section;
      // window.location.pathname = '/' + this.currentLanguage;
    }
  }
}
