import { ApplicationRef, Injectable } from "@angular/core";
import { configureChains, connect, fetchBalance, prepareWriteContract, readContracts, switchNetwork, watchAccount, watchNetwork, writeContract, disconnect, waitForTransaction, createConfig, mainnet } from '@wagmi/core'
import { HttpClient } from '@angular/common/http';
import { publicProvider } from '@wagmi/core/providers/public';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { TranslateService } from '@ngx-translate/core';
import { createPublicClient, encodeFunctionData, formatEther, http, parseEther } from 'viem';
import { bsc, bscTestnet, polygon, polygonMumbai, sepolia } from 'viem/chains';

import { PopupService } from './popup.service';
import { EventService } from './event.service';
import { SwapStatus, getBrowserName, getPremiumTokenRef, getParam, getParamWithoutCookie } from '../shared/constants/app-enums';
import { environment } from 'src/environments/environment';
import { ConfirmOptions, Connection, PublicKey } from "@solana/web3.js";

/**
 * variable used for the presale smart contract
 * of different network
 */

const presaleBsc = require('src/assets/contracts/presaleBSC.json');
const bsc_usdt_token = require('src/assets/contracts/bsc_usdt_token.json');
const token = require('src/assets/contracts/token.json');

/**
 * here the presale ABI and Address are set
 * for read, write and prepair contract
 */


const presaleContractBsc = {
  address: environment.bsc.presaleAddress as any,
  abi: presaleBsc.abi,
}

const bscUsdtContract = {
  address: environment.bsc.usdTAddress as any,
  abi: bsc_usdt_token.abi,
}

const tokenContract = {
  address: environment.tokenAddress as any,
  abi: token.abi,
}


@Injectable({
  providedIn: 'root',
})
export class WalletConnectService {
  swapStatus = SwapStatus.not_started;
  walletAddress: string = '';
  referUrl: string = '';
  client: any;
  wagmiData: any;
  chainId: any;
  mode: any;
  presaleData: any;
  swapHash: any = '';
  refreshId: any;
  refreshRate: number = 5000;

  /* variables used for Wallet connection */

  metaMaskConnector: any;
  walletConnector: any;
  walletConnectorBW: any;
  uriConnector: any;
  coinbaseConnector: any;
  isBW = false;

  /* predefined values of the essential variables */

  balanceData: {
    nativeBal: number,
    usdtBal: number,
    oneBnb: number,
    bnbUsdt: number,
    // polyUsdt: number
  } = {
      nativeBal: 0,
      usdtBal: 0,
      oneBnb: 0,
      bnbUsdt: 0,
    }

  /**
   * Tokenomics data is defining the no of preslae stages,
   * price of each token, minimum and maximum no of token
   * for each stages. It is also defining the end date of each presale stages.
   */
  tokenomics = [
    {
      minToken: 0,
      maxToken: 1000000000,      //1
      maxAmount: 22000000,
      tokenUSDT: 0.022,
      title: 'widget.round_title',
      endDate: 1706954399,
    }
  ];

  curWalletName: string = '';
  price = 150;
  constructor(
    private http: HttpClient,
    private appRef: ApplicationRef,
    private eventService: EventService,
    private popupService: PopupService,
    private transletService: TranslateService,

  ) {

    /**
     * Wagmi Core chain, provider and connector configuration.
     * If production mode then mainnet and paid RPC provider else
     * testnet and publicProvider
     */
    //Solana stuff
    this.http.get('https://api.diadata.org/v1/assetQuotation/Solana/0x0000000000000000000000000000000000000000')
      .subscribe((res: any) => {
        console.log(res.Price);
        this.price = res.Price;
      })
    let tempVar = JSON.stringify(this.tokenomics);
    const currentChain: any = environment.production ? [mainnet, bsc] : [sepolia, bscTestnet];
    const { chains, publicClient, webSocketPublicClient } = configureChains(
      currentChain,
      environment.production ?
        [
          jsonRpcProvider({
            rpc: (chain) => ({
              http: environment.bsc.rpcURL,
            }),
          }),
          jsonRpcProvider({
            rpc: (chain) => ({
              http: environment.bsc.backupRPC,
            }),
          }),
        ]
        : [publicProvider()]
    );

    /**
     * Setup of Metamask connector which will work
     * with metamask browser extension
     */

    this.metaMaskConnector = new MetaMaskConnector({
      chains: chains,
    });

    /**
     * Setup of wallet connector v2 which will work
     * with mobile app like trustwallet, alphawallet app.
     * the projectId is mandatory and can be generated from
     * https://cloud.walletconnect.com/sign-in
     */

    this.walletConnector = new WalletConnectConnector({
      chains,
      options: {
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49', 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0']
        }
      },
    });

    this.walletConnectorBW = new WalletConnectConnector({
      chains,
      options: {
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49']
        }
      },
    })

    this.coinbaseConnector = new CoinbaseWalletConnector({
      chains,
      options: {
        appName: '$SEAL',
        appLogoUrl: 'https://sealanacoin.com/assets/images/svg-icons/logo.svg',
      }
    })

    this.uriConnector = new WalletConnectConnector({
      chains,
      options: {
        showQrModal: false,
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49']
        }
      },
    });

    this.uriConnector.addListener('message', (uri: any) => {
      if (uri.type === 'display_uri') {
        const userAgent = window.navigator.userAgent.toLowerCase(),
          ios = /iphone|ipod|ipad/.test(userAgent);
        const bwUrl = getParamWithoutCookie('bwUrl');
        console.log("bwUrl=========>", bwUrl);
        let urlParams = uri.data + "&callbackUrl=" + window.location.href + "&browser=" + getBrowserName();
        console.log("url params=========>", urlParams);
        console.log("wondow log params=========>", window.location.href);
        if (ios) {
          const link = document.createElement('a');
          if (bwUrl && bwUrl != '') {
            link.href = bwUrl + urlParams;
            link.click();
            this.isBW = true;
          } else {
            // link.href = environment.bwUniversalLink + '/connect?' + urlParams;
            link.href = environment.bwDeepLink + urlParams;
            // link.target = '_blank';
            link.click();
            this.isBW = false;
          }
        } else {
          if (bwUrl && bwUrl != '') {
            const link = document.createElement('a');
            link.href = bwUrl + urlParams;
            link.click();
            this.isBW = true;
          } else {
            const link = document.createElement('a');
            // link.href = environment.bwUniversalLink + "/connect?" + urlParams;
            link.href = environment.bwDeepLink + urlParams;
            // link.target = '_blank';
            link.click();
            this.isBW = false;
          }
        }
      }
    });

    /**
     * wagmi createConfig setup for auto connect feature
     * and available connectors of wagmi.
     */

    this.client = createConfig({
      autoConnect: true,
      connectors: [this.metaMaskConnector, this.walletConnector, this.walletConnectorBW, this.uriConnector, this.coinbaseConnector],
      publicClient,
      webSocketPublicClient,
    });

    /**
     * watchNetwork function continuously monitors the userâ€™s
     * connected network. Whenever user switched/changed its
     * current network, this function keeps tracking that data
     * and updates the current chain id accordingly.
     */

    watchNetwork((network: any) => {
      this.chainId = network?.chain?.id;
      this.getPresalesData();
      setTimeout(() => this.eventService.setNetwork(), 2000);
      this.appRef.tick();
      // console.log('watchNetwork =', network);
    });

    /**
     * This function continuously monitors the userâ€™s connected
     * wallet account. Whenever user switched/changed its current account,
     * this function updates the current wallet address accordingly.
     */
    watchAccount((account: any) => this.setData(account));
  }

  /**
   * this is connect wallet function. The mode argument
   * is actually providing a choice to the app user whether
   * they want to connect via Metamask, WalletConnect V2 or web3Auth.
   * This function is mainly called from connect modal component.
   */

  async connectWallet(mode: string, isBsNetwork: boolean = false) {
    console.log('connectWallet called', mode)
    this.mode = mode;
    this.windowDataLayer('connectWallet', 'start', 1, 1, 0, '', '', 0, 0);
    await connect({
      connector: mode === 'metamask' ? this.metaMaskConnector
        : mode === 'wallet' ? this.walletConnector
          : mode === 'walletBW' ? this.walletConnectorBW
            : mode === 'coinbase' ? this.coinbaseConnector
              : mode === 'bw' ? this.uriConnector
                : this.walletConnector,
      chainId: environment.bsc.chainIdInt
    }).then(
      (success: any) => {
        console.log('\n==== wallet connected ====\n', this.client, '\n====');
        setTimeout(() => {
          this.sendDashFx('', 0, 0, true);
          this.windowDataLayer('connectWallet', 'successful', 2, 1, 0, '', '', 0, 0);
        }, 2000);
      },
      (err: any) => {
        console.log('\n==== wallet connection issue ====\n', err, '\n====');
      }
    );
  }

  /**
   * this is disconnect wallet function. After a successful disconnection
   * a developer can reset the different variables values.
   */

  async disConnectWallet() {
    await disconnect().then(
      (success: any) => {
        console.log('\n==== wallet disconnected ====\n', this.client);
        localStorage.clear();
        this.chainId = null;
        this.walletAddress = '';
        this.referUrl = '';
        this.balanceData.nativeBal = 0;
        this.balanceData.usdtBal = 0;
        this.eventService.setNetwork();
        this.eventService.isConnected = false;
        this.getPresalesData();
      },
      (err: any) => {
        console.log('\n==== wallet disconnect issue ====\n');
        console.log('disconnect issue =', err);
      }
    );
  }

  /**
   * This function is getting called from watchAAccount.
   * Here we are saving the wagmi wallet address and
   * also calling the getPresale function for re-read
   * the smart contract function with new wallet address
   */

  setData(account: any): void {
    this.wagmiData = account;
    this.walletAddress = this.wagmiData.address?.toLowerCase();
    this.refreshRate = account.address ? 5000 : 50000;
    this.eventService.isConnected = this.wagmiData?.isConnected;
    this.appRef.tick();
    this.getPresalesData();
  }

  /**
   * In thiss fnctiion we are calling the different method
   * of smart contract to get different values
   */

  async getPresalesData() {
    // if (this.walletAddress && this.referUrl == '') this.refer();
    const config = {
      contracts: [
        {
          ...presaleContractBsc,
          functionName: 'fetchPrice',           // this method is returning the amount of bnb required for 1 app token.
          chainId: environment.bsc.chainIdInt,    // 0
          args: [100000]
        },
        {
          ...presaleContractBsc,
          functionName: 'getLatestPrice',           // this method is returning the amount of bnb required for 1 app token.
          chainId: environment.bsc.chainIdInt,    // 0
          args: []
        }
      ],
    };

    /**
     * Above contracts are going to be read.
     */

    this.presaleData = await readContracts(config);
    console.log('\n******presaleData =', this.presaleData, '\n******\n');

    /* assigning the value of no of token sold va bsc and eth contract */

    try {
      /* assigning the value of total USDT raised via eth and bsc */
      this.balanceData.bnbUsdt = Number(this.presaleData[0].result) / 100000;
      let bnbPrice = +formatEther(this.presaleData[1].result); // no of app tokens in exchange of 1 eth
      this.balanceData.oneBnb = this.balanceData.bnbUsdt / bnbPrice;
    } catch (e) {
      console.log("Presale over.", e);
    }

    if (this.wagmiData?.isConnected) this.getMetaData(); // if user is connected then call this function
    if (this.refreshId) clearTimeout(this.refreshId);
    this.refreshId = setTimeout(() => this.getPresalesData(), this.refreshRate); // Call the self method on each 5secs interval

  }

  /**
   * This method is used to fetch the native balance
   * of the current account and network
   */

  async getMetaData() {
    const nativeBalance = await fetchBalance({
      address: this.walletAddress as any,
      formatUnits: 'ether',
    });

    const usdtBal = await fetchBalance({
      address: this.walletAddress as any,
      // formatUnits: 'mwei',
      token: this.getChainData().usdTAddress as any,
      chainId: this.getChainData().chainIdInt,
    });

    /* user's Eth/Bnb and USDT balance */
    this.balanceData.nativeBal = +nativeBalance.formatted;
    this.balanceData.usdtBal = +usdtBal.formatted;

  }

  /**
   * switching the current network. If user is on wrong network
   * and got error on switching then a popup
   * will appear saying to connect correct chain
   */
  async switchNetwork(chainId: number) {
    await switchNetwork({
      chainId: chainId,
    }).then((success: any) => {
      this.appRef.tick();
    },
      (err: any) => {
        const chain = environment.bsc;
        const chainName = chain.chainInfo.params[0].chainName;
        this.popupService.messagePopup('info', this.transletService.instant('switchNetwork', { chain_name: chainName, }));
      }
    );
  }

  /**
   * calculating the available no of token in exchange of inserted Eth amount
   */

  getNativeAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.oneBnb;
        break;

      default:
        singleAmount = this.balanceData.oneBnb;
    }
    return Math.floor(+(amount / +singleAmount));
  };

  /**
   * calculating the available no of token in exchange of inserted Usdt amount
   */

  getUSDTAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.bnbUsdt;
        break;
      // case environment.poly.chainIdInt:
      //   singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
      //   break;
      default:
        singleAmount = this.balanceData.bnbUsdt;
    }
    return Math.floor(+(amount / +singleAmount));
  };


  /**
   * reverse calculation required amount (eth/bnb/usdt)
   * in exchange of inserted token
   * @param amount : no of token
   * @param mode : exchange mode
   * @returns : required amount
   */

  getDynamicAmount = async (amount: number, mode: string) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.bsc.chainIdInt:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneBnb;
        } else {
          singleAmount = this.balanceData.bnbUsdt
        } break;

      // case environment.poly.chainIdInt:
      //   if (mode === 'getNativeAmount') {
      //     singleAmount = this.balanceData.onePoly;
      //   } else {
      //     singleAmount = this.balanceData.polyUsdt / Math.pow(10, 6)
      //   } break;

      default:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneBnb;
        } else {
          singleAmount = this.balanceData.bnbUsdt
        }
        break;
    }
    return +(amount * singleAmount);
  };

  /**
   * this function is swaping the native token with app token
   * @param amount: app token amount
   * @param value: eth token value
   */

  async swapNativeTokens(
    amount: number,
    value: number,
    isBuyStake: boolean = false
  ) {
    // setting swapStatus to not started
    this.swapStatus = SwapStatus.not_started;

    /**
     * preparing a contract for swaping via smart contract
     * method of ethBuyHelper
     */
    const { request } = await prepareWriteContract({
      ...this.getPresaleContract(),
      functionName: this.getChainData().nativeFunction,
      chainId: this.getChainData().chainIdInt,
      args: [amount],
      value: parseEther(`${value}`),
      account: this.walletAddress as any, // Optional param. Not required!
    });

    // setting swapStatus to confirmation pending
    this.swapStatus = SwapStatus.confirm_pending;

    /**
     * Writing contract for swap process
     */
    await writeContract(request).then(
      (success: any) => {
        console.log('Eth write Contract success', success);
        this.swapStatus = SwapStatus.in_progess;
        this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0);
        // this.sendDashFx(this.getChainData().purchaseToken, amount, value);
        this.checkTansaction(success.hash, this.getChainData().purchaseToken, amount, value);
      },
      (err: any) => {
        console.log('swapNativeTokens Error =', err);
        if (err.code === 4001) {
          this.swapStatus = SwapStatus.rejected;
        } else {
          this.swapStatus = SwapStatus.failed;
        }
      }
    );
  }

  /**
   * this function is checking allowence
   * amount before swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async swapCryptoForUSDT(
    amount: number,
    value: number,
    afterAllowance: boolean = false,
    isBuyStake: boolean = false
  ) {
    /**
     * preparing token contract with ABI
     */
    const usdtContract = this.getUSDTContract();

    // set presale address based on current network
    const presaleAdd = this.getChainData().presaleAddress as any;

    /**
     * reading allowence contract
     */
    const allowanceData = await readContracts({
      contracts: [
        {
          ...usdtContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, presaleAdd],
        },
      ],
    });

    //  checking provided allowence value in token contract

    let allowanceValue = Number(allowanceData[0].result) / Math.pow(10, 6);
    console.log('allowanceValue =', value, allowanceValue);

    /**
     * if inserted usdt value is larger than
     * provided allowence value then it needs further approval.
     * below we are preparing contract for allowence approval
     */

    if (+value > +allowanceValue) {
      const { request } = await prepareWriteContract({
        ...usdtContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [presaleAdd, afterAllowance || allowanceValue == 0 ? '100000000000000000000000000' : '0'],
        account: this.walletAddress as any, // Optional param. Not required!
      });

      // setting swapStatus to approval pending
      this.swapStatus = SwapStatus.approval_pending;

      // fetching transaction hash key of writing contract
      const { hash } = await writeContract(request);
      console.log('approve allowence hash key =', hash);

      /**
       * checking the approval transaction status.
       * if success and the allowence is preapproved
       * then it will call the buyUSDT function, else
       * will again go through the starting steps but this time with
       * allowence approval true mode.
       */
      await waitForTransaction({ hash }).then(
        (success: any) => {
          console.log('approve Config success', success);
          if (afterAllowance) {
            this.buyUSDT(amount, value, isBuyStake);
          } else {
            this.swapCryptoForUSDT(amount, value, true, isBuyStake);
          }
        },
        (err: any) => {
          console.log('approve Config error', err);
          this.swapStatus = SwapStatus.rejected;
        }
      );
    } else {

      /**
       * if inserted usdt value is smaller than
       * provided allowence value, then buyUSDT
       * function will be called and doesn't need
       * to check for allowence approval
       */
      this.buyUSDT(amount, value, isBuyStake);
    }
  }

  /**
   * this function is swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async buyUSDT(amount: number, value: number, isBuyStake: boolean = false) {
    /**
     * preparing a contract for swaping via smart contract
     * method of buyWithUSDT
     */

    console.log('buyUSDT called', this.getChainData().hasStaking)
    const contract = this.getPresaleContract();
    await prepareWriteContract({
      ...contract,
      functionName: 'buyWithUSDTDynamic',
      chainId: this.chainId,
      args: this.getChainData().hasStaking ? [amount, isBuyStake] : [amount],
      account: this.walletAddress as any, // Optional param. Not required!
    })
      // if prepare contract is success
      .then(
        async (request: any) => {
          // setting swapStatus to confirmation pending
          console.log('usdtBuyHelper =', request);
          this.swapStatus = SwapStatus.confirm_pending;

          /**
           * Once confirmed startig write contract
           */
          await writeContract(request.request).then(
            (success: any) => {
              console.log('buyUSDT writeContract success', success);
              this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0); // setting windowdatalayer for analytics
              // this.sendDashFx('usdt', amount, value); // updating dashFx data
              this.swapStatus = SwapStatus.in_progess; // setting swapStatus to inprogress mode
              this.checkTansaction(success.hash, 'usdt', amount, value); // checking transaction status
            },
            (err: any) => {
              console.log('buyUSDT writeContract error', err);
              this.swapStatus = SwapStatus.failed;
            }
          );
        },

        /**
         * if there is any error while preparing contract
         * like requesting more than max-token buy limit
         * then a warning popup will appear saying that
         * the max-token limitation
         */
        (err: any) => {
          // this.popupService.messagePopup(
          //   'warning',
          //   this.transletService.instant('widget.max_token_exceed', {
          //     maxTokensToBuy: this.balanceData.maxTokensToBuy,
          //   }),
          //   this.transletService.instant('widget.max_token_title')
          // );
          this.swapStatus = SwapStatus.rejected;
          console.log(err.message);
        }
      );
  }

  /**
   * this function is check the final
   * status of the current transaction
   * @param haskKey: transaction hash key
   * @param mode: Eth/Bnb/Usdt transaction
   * @param amount: no of tooken
   * @param value:  amount of eth/bnb/usdt
   */

  async checkTansaction(
    haskKey: string,
    mode: string,
    amount: number,
    value: number,
    isDatalayer: boolean = true
  ) {
    await waitForTransaction({ hash: haskKey as any }).then(
      (response: any) => {
        console.log('checkTansaction success', response);
        this.getMetaData(); // update the current native balance
        this.swapStatus = SwapStatus.complete; // setting swapStatus to completed
        this.swapHash = response.transactionHash; // assigning succes transaction hash id
        this.sendBWData(mode, amount, value);
        if (isDatalayer) this.windowDataLayer('swap', 'swapSuccessful', 3, 1, 0, mode, environment.tokenName, value, amount);
      },
      (err: any) => {
        console.log('checkTansaction error', err);
        this.getMetaData(); // update the current native balance
        this.swapStatus = SwapStatus.failed; // setting swapStatus to failed
      }
    );
  }

  /**
   * function to update analytics data
   * @param flowName: process name like swap/connectWallet
   * @param stepName: swapSuccessful/confirmTransaction/successful
   * @param stepNo: step no of process
   * @param completeFlag: is omppletedd or not
   * @param errorCode: if any error
   * @param fromCurrency: ETH/BNB/USDT
   * @param toCurrency : ICO/APP token
   * @param fromVal: ETH/BNB/USDT value
   * @param toVal: App token value
   */
  async windowDataLayer(
    flowName: string,
    stepName: string,
    stepNo: number,
    completeFlag: number,
    errorCode: number,
    fromCurrency: string = '',
    toCurrency: string = '',
    fromVal: number = 0,
    toVal: number = 0
  ) {
    if (environment.production) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'workflowStep',
        walletAddress: this.walletAddress,
        workflowName: flowName, // "swap",
        workflowStepNumber: stepNo, // 2,
        workflowStepName: stepName, // "confirmTransaction",

        workflowCompleteFlag: completeFlag,
        workflowErrorCode: errorCode,

        transactionId: completeFlag === 0 ? undefined : this.swapHash,
        swapFromValue: completeFlag === 0 ? undefined : fromVal, // value,
        swapToValue: completeFlag === 0 ? undefined : toVal, // amount,
        swapFromCurrency: completeFlag === 0 ? undefined : fromCurrency, // "ETH/BNB/USDT",
        swapToCurrency: completeFlag === 0 ? undefined : toCurrency, // "token name"
        presaleStage: completeFlag === 0 ? undefined : 0, // current running stage
        stageTokenValue: completeFlag === 0 ? undefined : this.tokenomics[0].tokenUSDT, // current running stage's token usdt value
        swapFromValueUsd: completeFlag === 0 ? undefined : await this.getDynamicAmount(toVal, 'getUSDTAmount'),
      });
    }
  }

  /**
   * DashFx is another analytics feature
   * @param type : connect wallet/ETH/BNB/USDT
   * @param toValue: App token value
   * @param fromValue : ETH/BNB/USDT value
   * @param connectMode: whether it is wallet connect or not
   */

  sendDashFx = (
    type: string,
    toValue: number,
    fromValue: number,
    connectMode: boolean = false
  ) => {
    if (environment.production && connectMode) {
      const data = {
        walletAddress: this.walletAddress,
        iid: environment.iidDashFx,
        event: 'lead_success',
        purchaseType: connectMode ? null : type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[0].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source'),
        tid: getParam('tid'),
        pid: getParam('pid')
      };
      this.http.post(environment.urlDashFx, data).subscribe(
        (res: any) => {
          console.log('DashFx success response', res);
        },
        (err: any) => {
          console.log('DashFx error response', err);
        }
      );
    }
  };


  /**
* BestWallet Data analytics
* @param type : connect wallet/ETH/BNB/USDT
* @param toValue: App token value
* @param fromValue : ETH/BNB/USDT value
* @param connectMode: whether it is wallet connect or not
*/

  sendBWData = (type: string, toValue: number, fromValue: number) => {
    if (environment.production) {
      const data = {
        publicAddress: this.walletAddress,
        event: 'revenue',
        purchaseType: type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[0].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source'),
        hash: this.swapHash,
        chainId: this.chainId,
        presaleName: "$SEAL",
        isBW: this.isBW,
        rABsZdfEqoXvExie: getParamWithoutCookie('rABsZdfEqoXvExie') || undefined,
      }

      this.http.post(environment.bwApiUrl, data).subscribe((res: any) => {
        console.log('BW success response', res);
      },
        (err: any) => {
          console.log('BW error response', err);
        });
    }
  }


  getChainData() {
    switch (this.chainId) {
      case environment.bsc.chainIdInt:
        return environment.bsc;
      // case environment.poly.chainIdInt:
      //   return environment.poly;
      default:
        return environment.bsc;
    }
  }

  getPresaleContract() {
    switch (this.chainId) {
      case environment.bsc.chainIdInt:
        return presaleContractBsc;
      // case environment.poly.chainIdInt:
      //   return presaleContractPoly;
      default:
        return presaleContractBsc;
    }
  }

  getUSDTContract() {
    switch (this.chainId) {
      case environment.bsc.chainIdInt:
        return bscUsdtContract;
      // case environment.poly.chainIdInt:
      //   return polyUsdtContract;
      default:
        return bscUsdtContract;
    }
  }


  /**
  * detect current window object
  */

  getWindowObject(walletName: string = '') {
    switch (walletName !== '' ? walletName : this.curWalletName) {
      case 'Phantom': return window.solana;
      case 'Solflare': return window.solflare;
      // case 'Coinbase': return window.coinbaseSolana;
      // case 'Trust': return window.trustWallet.solana;
      default: return window.solana;
    }
  }
}
