export const environment = {
  production: true,
  bsc: {
    chainId: "0x38", //hexadecimal for metamask
    chainIdInt: 56,
    chainName: "BSC Mainnet",
    gasFees: 0.0025,
    purchaseToken: "BNB",
    hasStaking: false,
    nativeFunction: "buyWithBNBDynamic",
    presaleAddress: "0x0B814dFf67adCE61dA507a5812a5309b7aDb9519",
    usdTAddress: "0x55d398326f99059fF775485246999027B3197955",
    rpcURL: "https://weathered-quick-panorama.bsc.quiknode.pro/fb0efe91ce8a7bccaaa68a98a211980bc48c89f0/",
    backupRPC: "https://weathered-quick-panorama.bsc.quiknode.pro/fb0efe91ce8a7bccaaa68a98a211980bc48c89f0/",
    blockExplorerURL: "https://bscscan.com/tx/",
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: "0x38",
        chainName: "BSC Mainnet",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed2.ninicoin.io/"],
        blockExplorerUrls: ["https://bscscan.com/"],
      }]
    }
  },
  minToken: 10,
  tokenAddress: '0x8B2a6F37996977458799034119969af90A0F6e19',
  tokenName: '$SEAL',
  iidDashFx: 1668,
  urlDashFx: 'https://m75rs6ploizbuuwhp4mlvys63e0xswbo.lambda-url.eu-west-2.on.aws/',
  wertOrigin: 'https://widget.wert.io',
  partnerId: '01HXH45F82BY0EE7Z848G4T7N3',
  wertUrl: 'https://wpwbnylbjtqmwj5zvbm4ev5rse0leype.lambda-url.eu-west-2.on.aws/',
  walletConnectId: 'cd8c5fb1836d8f7529e5bc4e6df31df5',
  bwUniversalLink: 'https://best-wallet-staging.web.app',
  bwDeepLink: 'bw-staging://app/connect?',
  bwApiUrl: 'https://stage-api.bestwallet.com/presale-transactions',
  showBestWallet: true,
};
