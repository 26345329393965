<section class="how-to-buy" id="how_to">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <h3 translate>how_to_title</h3>
                <p class="mb-4" translate>how_to_para1_evm</p>
                <p translate>how_to_para2_evm</p>
                
            </div>
            <div class="col-lg-6">
                <img src="/assets/images/how-to-img.png" class="img-fluid  d-block ">
                 <img src="/assets/images/how-to-mob.png" class="img-fluid  d-block d-md-none">
            </div>
        </div>
    </div>
    <img src="/assets/images/how-to-bottom.png" class="img-fluid how-to-bottom  d-block ">
</section>